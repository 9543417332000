// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import Indicator from "./Indicator";
import { Cart20Svg, Cross10Svg } from "../../svg";
import { cartRemoveItem } from "../../store/cart";
import { getNoImg, url } from "../../services/utils";

function IndicatorCart(props) {
    const { cart, cartRemoveItem, from } = props;
    let dropdown;
    let totals;
    const items = cart.items.map((item, index) => {
        let options;
        let image;
        let addOns;

        if (!(item.variantGroup?.length == 1 && item.variantGroup[0]?.name?.toLowerCase() == "main")) {
            options = (
                <ul className="dropcart__product-options mt-0">
                    <li>
                        {item.variantGroup?.map((option, index) => {
                            return option.name + (index + 1 == item.variantGroup.length ? "" : ", ");
                        })}
                    </li>
                </ul>
            );
        }

        if (item.selected_addons?.length > 0) {
            addOns = (
                <ul className="dropcart__product-options mt-0 addons-list">
                    {item.selected_addons?.map((addon, addonIndex) => {
                        return (
                            <li key={index + addon.uuid + addonIndex}>
                                + {addon.info.name} (
                                <Currency value={addon.info.extra_price} />)
                            </li>
                        );
                    })}
                </ul>
            );
        }

        image = (
            <div className="product-image dropcart__product-image">
                {item.status !== 10 ? (
                    <div className="product-image__body">
                        <img
                            className="product-image__img radius"
                            src={item.image ? item.image : getNoImg()}
                            onError={(e) => (e.target.src = getNoImg())}
                            alt={item.product.name}
                        />
                    </div>
                ) : (
                    <Link to={url.product(item.product)} className="product-image__body">
                        <img
                            className="product-image__img radius"
                            src={item.image ? item.image : getNoImg()}
                            onError={(e) => (e.target.src = getNoImg())}
                            alt={item.product.name}
                        />
                    </Link>
                )}
            </div>
        );

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item, item.quantity)}
                render={({ run, loading }) => {
                    const classes = classNames("dropcart__product-remove btn btn-light btn-sm btn-svg-icon", {
                        "btn-loading": loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={index} className={"dropcart__product" + (item.status !== 10 ? " inactive" : "")}>
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={url.product(item.product)}>{item.product.name}</Link>
                    </div>
                    {item.status == 10 ? (
                        <>
                            {options}
                            {addOns}
                            <div className="dropcart__product-meta">
                                <span className="dropcart__product-quantity">{item.quantity}</span>
                                {" × "}
                                <span className="dropcart__product-price">
                                    <Currency value={item.price} />
                                </span>
                            </div>
                        </>
                    ) : (
                        <div className="round-label">{item.stockQty == 0 ? "Out Of Stock" : "Invalid Items"}</div>
                    )}
                </div>
                {removeButton}
            </div>
        );
    });

    if (cart.items.length) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">{items}</div>

                {/* <div className="dropcart__totals">
          <table>
            <tbody>
              {totals}
              <tr>
                <th>Total</th>
                <td>
                  <Currency value={cart.total} />
                </td>
              </tr>
            </tbody>
          </table>
        </div> */}

                <div className="dropcart__buttons">
                    <Link className="btn btn-primary btn-sm" to="/cart">
                        View Cart
                    </Link>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">Your shopping cart is empty!</div>
            </div>
        );
    }

    return (
        <Indicator
            url="/cart"
            dropdown={dropdown}
            value={cart.quantity}
            icon={<span className={cart.quantity == 0 && from === "header" ? "no-item" : null}>{<Cart20Svg />}</span>}
        />
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);

import { STORE_GENERAL_INFO, STORE_COUNTRIES } from './generalInfoTypes';

// eslint-disable-next-line import/prefer-default-export
export function storeGeneralInfo(generalInfo) {
    return {
        type: STORE_GENERAL_INFO,
        generalInfo,
    };
}

export function storeCountries(countries) {
    return {
        type: STORE_COUNTRIES,
        countries,
    };
}
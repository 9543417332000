import { getAuthHeader, appendFormData, paramBuilder } from "../services/utils";
const axios = require("axios");

export async function getProfile() {
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile";
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function updateProfile(data) {
  var formData = appendFormData(data);

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile?_method=PUT", formData, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error?.message)
        }
      });
  });
}

export async function changePassword(data) {
  var formData = appendFormData(data);

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/change-password?_method=PUT", formData, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function getAddress(params) {
  const queryString = paramBuilder(params)
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address" + queryString;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error?.message)
        }
      });
  });
}

export async function addAddress(data) {
  var formData = appendFormData(data);

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address", formData, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error?.message)
        }
      });
  });
}

export async function updateAddress(data) {
  var formData = appendFormData(data);

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address?_method=PUT", formData, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error?.message)
        }
      });
  });
}

export async function deleteAddress(data) {
  var formData = appendFormData(data);

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/address?_method=DELETE", formData, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error?.message)
        }
      });
  });
}

export async function resendVerificationLink(data) {
  var formData = appendFormData(data);

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/email/resend", formData, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error?.message)
        }
      });
  });
}

export async function getRewardPoints(params) {
  const queryString = paramBuilder(params)
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/my-profile/reward-point" + queryString;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error?.message)
        }
      });
  });
}
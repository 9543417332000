export const userTitleList = [
    { value: "mr", label: "Mr" },
    { value: "mrs", label: "Mrs" },
    { value: "ms", label: "Ms" },
    { value: "other", label: "Other" },
]


export const addressTypeList = [
    { code: "0", name: "Shipping" },
    { code: "1", name: "Billing" }
]


export const orderStatusList = [
    { code: "0", name: "Pending" },
    { code: "10", name: "Processing" },
    { code: "15", name: "Waiting Restock" },
    { code: "20", name: "Ready" },
    { code: "25", name: "Delivering" },
    { code: "30", name: "Completed" },
    { code: "130", name: "Cancelled" },
    { code: "100", name: "Pending Refund" },
    { code: "120", name: "Refunded" },
    { code: "110", name: "Refund Cancelled" },
    { code: "140", name: "Failed Payment" },
]


export const paymentStatusList = [
    { code: "0", name: "Pending Payment" },
    { code: "10", name: "Paid" },
    { code: "20", name: "Payment Rejected" },
    { code: "30", name: "Payment Cancelled" },
]


export const voucherUsageTypeList = [
    { code: "0", name: "All" },
    { code: "1", name: "Member" },
    { code: "2", name: "Guest" },
]

export const socialIconsList = [
    { id: 1, url: "https://www.facebook.com/VanzoOfficial", img: "images/icons/facebook_icon.png" },
    { id: 2, url: "https://www.facebook.com/VanzoMY", img: "images/icons/facebook_icon.png" },
    { id: 3, url: "https://www.instagram.com/vanzo_official/", img: "images/icons/instagram_icon.png" },
    { id: 4, url: "https://www.instagram.com/vanzo_asia/", img: "images/icons/instagram_icon.png" },
];

export const eventTypeList = [
    { code: 10, name: "Discount" },
    { code: 20, name: "Voucher" },
    { code: 30, name: "PWP Combo" },
    { code: 40, name: "PWP Bundle" },
    { code: 50, name: "PWP Free Gift" },
]
import { getAccessToken } from "../api/auth";
import React, { useState, useEffect } from 'react'
import Cookies from "universal-cookie";
import { logout } from "../api/auth";
import { toast } from "react-toastify";
import Currency from "../components/shared/Currency";

const cookies = new Cookies();
var uuid = require("uuid");

export const url = {
    home: () => '/',

    category: (category) => `/category/${category.slug}`,

    brand: (brand) => `/brand/${brand.slug}`,

    product: (product) => `/product/${product.slug}`,

    addOnDeals: (product) => `/addon-deal/${product.slug}`,

    comboDeals: (product) => `/combo-deal/${product.slug}`,

    dynamicPage: (url) => `/page/${url}`,

};

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}

export function getAuthHeader() {
    let token = getAccessToken();
    if (token) {
        return {
            Accept: "application/json",
            Authorization: "Bearer " + getAccessToken(),
            'Content-Type': 'multipart/form-data',
        };
    } else {
        return {
            Accept: "application/json",
            'Content-Type': 'multipart/form-data',
        };
    }
}

export function checkVisitorID() {
    let hasCartVID = true
    if (cookies.get("cartvid") == undefined) {
        hasCartVID = false
        let vID = uuid.v4();
        var expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth() + 36);
        let settings = {
            path: "/",
            expires: expiryDate,
        }
        if (process.env.REACT_APP_DOMAIN)
            settings.domain = process.env.REACT_APP_DOMAIN

        cookies.set("cartvid", vID, settings);
    }
    return hasCartVID
}

export function getDefaultAvatar() {
    return "/images/avatars/defaultAvatar.jpg";
}

export function getNoImg(size) {
    if (size == 'small') {
        return "/images/no_image_276px.png";
    } else
        return "/images/no_image.png";
}

export function makeTitle(slug) {
    var words = [];
    if (slug) {
        words = slug.split("-");

        for (var i = 0; i < words.length; i++) {
            var word = words[i];
            words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
    }

    return words ? words.join(" ") : slug;
}

export function checkUnauthorized(err, history) {
    if (err.http_code == 401 || err.http_code == 403) {
        for (const errKey in err.data?.errors) {
            if (errKey.split('.')[0] == 'token') {
                if (getAccessToken()) {
                    toast.error("Sorry, your login session expired. Please try again.");
                    if (history) logout(history);
                    else logout();
                }
                break;
            }
        }
        return true;
    } else {
        return false;
    }
}

export function cleanFormData(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
            delete obj[propName];
        }
    }
    return obj;
}

/* Append data into FormData instance */
export function appendFormData(data) {
    var formData = new FormData();
    for (var key in data) {
        if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
        }
    }
    return formData;
}

export function paramBuilder(rawParam) {
    let param = ""
    for (var key in rawParam) {
        if (rawParam[key]) {
            let symbol = param == '' ? '?' : '&';
            param = param + symbol + key + '=' + rawParam[key];
        }
    }
    return param;
}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export function allEqual(arr, type) {

    if (type == 'price') {
        arr = arr.map((item) => {
            return parseFloat(item).toFixed(2);
        })
    }
    return new Set(arr).size == 1;
}

export function showGeneralError() {
    return toast.error("The system has encountered an unknown error. Please try again later.");
}

export const returnDiscountType = (typeID) => {
    return typeID == 10 ? "RM" : "%";
};
export function returnResponsiveImg(imageObj) {
    if (imageObj.responsive_url && imageObj.responsive_url.length > 0) {
        if (imageObj.responsive_url.length > 1) {
            let middle = Math.round(imageObj.responsive_url.length / 2);
            return imageObj.responsive_url[middle];
        } else return imageObj.responsive_url[0];
    } else {
        return imageObj.url;
    }
}

export const redirectTo404 = () => {
    if (window.location.pathname == "/goto-404") return null
    else
        return (window.location.href = "/goto-404");
};

export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    let array = Array.from(map, ([name, value]) => ({ name, value }));
    return array;
}

export function compareProductIsSame(item, item2) {
    return item.parent_hash == null &&
        item.selected_variant_group.uuid == item2.selectedVariant.uuid
};

export function sortByIsRequired(a, b) {
    if (a.is_required > b.is_required) {
        return -1;
    }
    if (a.is_required < b.is_required) {
        return 1;
    }
    return 0;
}

export const renderComboDealsCondition = (condition, type) => {
    if (condition.is_greater == 0) {
        return (type == 'title' ? <h4>
            Buy {condition.qty} at <Currency value={parseFloat(condition.price_per_item) * parseInt(condition.qty)} />
        </h4> : <>
            Buy {condition.qty} at <Currency value={parseFloat(condition.price_per_item) * parseInt(condition.qty)} />
        </>
        )
    } else {
        return (
            (type == 'title' ? <h4>
                Buy more than {condition.qty} for <Currency value={condition.price_per_item} /> each
            </h4> : <>
                Buy more than {condition.qty} for <Currency value={condition.price_per_item} /> each
            </>
            )
        )
    }
}
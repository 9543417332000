// react
import React from "react";

// data stubs
import theme from "../../data/theme";

export default function FooterContacts(props) {
    const { storeInfo } = props;
    return (
        <div className="site-footer__widget footer-contacts">
            {/* <h5 className="footer-contacts__title">Contact Us</h5> */}

            <b className="footer-contacts__text">Address</b>
            <ul className="footer-contacts__contacts">
                {storeInfo?.company_info?.address1 ? (
                    <li >
                        {storeInfo?.company_info?.address1 +
                            (storeInfo?.company_info?.postcode ? ", " + storeInfo?.company_info?.postcode : "") +
                            (storeInfo?.company_info?.city ? ", " + storeInfo?.company_info?.city : "") +
                            (storeInfo?.company_info?.state ? ", " + storeInfo?.company_info?.state : "") +
                            (storeInfo?.company_info?.country ? ", " + storeInfo?.company_info?.country : "")}
                    </li>
                ) : null}
                <br />
                <b className="footer-contacts__text">Contact</b>
                {storeInfo?.contact_info?.number1 || storeInfo?.contact_info?.number2 ? (
                    <li>
                        <a href={"tel:" + storeInfo?.contact_info?.number1}>{storeInfo?.contact_info?.number1}</a>
                    </li>
                ) : null}

                {storeInfo?.contact_info?.number2 ? (
                    <li>
                        <a href={"tel:" + storeInfo?.contact_info?.number2}>{storeInfo?.contact_info?.number2}</a>
                    </li>
                ) : null}

                <br />
                <b className="footer-contacts__text">Email</b>
                {storeInfo?.contact_info?.email ? (
                    <li>
                        <a href={"mailto:" + storeInfo?.contact_info?.email}>{storeInfo?.contact_info?.email}</a>
                    </li>
                ) : null}
            </ul>
        </div>
    );
}

export default [
    {
        id: 1,
        name: "My Account",
        url: "/account/profile",
    },
    {
        id: 2,
        name: "My Settings",
        url: "/account/departure",
    },
    // {
    //     id: 2,
    //     name: "Addresses",
    //     url: "/account/addresses",
    // },
    // {
    //     id: 3,
    //     name: "Order History",
    //     url: "/account/orders",
    // },
    // {
    //     id: 4,
    //     name: "My Vouchers",
    //     url: "/account/vouchers",
    // },
    // {
    //     id: 5,
    //     name: "Rewards history",
    //     url: "/account/rewards-history",
    // },
];

// react
import React from "react";

// third-party
import classNames from "classnames";
import { Link } from "react-router-dom";

// application
import Currency from "../shared/Currency";
import { getNoImg, returnResponsiveImg, url, allEqual } from "../../services/utils";

function Suggestions(props) {
    const { context, className, products, clearSearch, searchInput } = props;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);
    const list = [
        products &&
            products.map((product) => (
                <li key={product.uuid} className="suggestions__item">
                    <div className="suggestions__item-image product-image">
                        <div className="product-image__body">
                            <img
                                className="product-image__img"
                                src={
                                    product.images && product.images[0] && product.images[0].url
                                        ? returnResponsiveImg(product.images[0],'smallest')
                                        : getNoImg()
                                }
                                onError={(e) => (e.target.src = getNoImg())}
                                alt={product.name}
                            />
                        </div>
                    </div>
                    <div className="suggestions__item-info">
                        <Link className="suggestions__item-name" to={url.product(product)} onClick={() => clearSearch()}>
                            {product.name}
                        </Link>
                        <div className="suggestions__item-meta">{product.product_category_info?.name}</div>
                    </div>
                    <div class="suggestions__item-price">
                        {!product.is_sales && allEqual(product.price) ? (
                            <Currency value={product.price} />
                        ) : product.is_sales && allEqual(product.sales_price) ? (
                            <span className="product__new-price">
                                <Currency value={product.sales_price} />
                            </span>
                        ) : product.is_sales ? (
                            <>
                                <Currency value={product.sales_price[0]} />{" "}
                                {product.sales_price[product.sales_price.length - 1] ? " - " : null}{" "}
                                {product.sales_price[product.sales_price.length - 1] ? (
                                    <Currency value={product.sales_price[product.sales_price.length - 1]} />
                                ) : null}
                            </>
                        ) : (
                            <>
                                <Currency value={product.price[0]} /> {product.price[product.price.length - 1] ? " - " : null}{" "}
                                {product.price[product.price.length - 1] ? (
                                    <Currency value={product.price[product.price.length - 1]} />
                                ) : null}
                            </>
                        )}
                    </div>
                </li>
            )),
    ];

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">{list}</ul>
        </div>
    );
}

export default Suggestions;

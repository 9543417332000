import { getAuthHeader, appendFormData, paramBuilder } from "../services/utils";
const axios = require("axios");
let apiRejectRequest = null;
let brandRejectRequest = null;


export async function getBrands(params, source) {
  // cancel previous ajax
  if (brandRejectRequest) {
    brandRejectRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  brandRejectRequest = axios.CancelToken.source();


  params.is_visible = 1
  const queryString = paramBuilder(params)
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/brand" + queryString;


  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
        cancelToken: source ? source?.token : brandRejectRequest.token
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          if (!axios.isCancel(error)) {
            reject(error?.message)
          }
        }
      });
  });
}

export async function getBrandDetail(slug) {
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/brand/" + slug;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error?.message)
        }
      });
  });
}

export async function getProductCategories(params, source) {
  params.is_visible = 1
  params.sort_by = 'created_at'
  params.sort_type = 'asc'
  const queryString = paramBuilder(params)
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/product-category" + queryString;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
        cancelToken: source?.token
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error?.message)
        }
      });
  });
}

export async function getProductCategoryBySlug(slug) {
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/product-category/" + slug;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error?.message)
        }
      });
  });
}

export async function getProductDetails(slug) {
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/product/" + slug;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          reject(error?.message)
        }
      });
  });
}


export async function getProductList(params, source) {
  params.status = 10
  // cancel previous ajax
  if (apiRejectRequest) {
    apiRejectRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  apiRejectRequest = axios.CancelToken.source();

  const queryString = paramBuilder(params)
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/product" + queryString;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
        cancelToken: source ? source?.token : apiRejectRequest.token
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        } else {
          if (!axios.isCancel(error)) {
            reject(error?.message)
          }
        }
      });
  });
}

import { UPDATE_USER, UPDATE_NOTIFICATION } from './userActionTypes';

const initialState = {
    profile: null,
    notification: 0
};

export default function userReducer(state = initialState, action) {
    if (action.type === UPDATE_USER) {
        return { ...state, profile: action.profile };
    }
    else if (action.type === UPDATE_NOTIFICATION) {
        return { ...state, notification: action.notification };
    }
    return state;
}

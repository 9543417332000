// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";

// application
import MobileLinks from "./MobileLinks";
import { Cross20Svg } from "../../svg";
import { currencyChange } from "../../store/currency";
import { localeChange } from "../../store/locale";
import { mobileMenuClose } from "../../store/mobile-menu";

// data stubs
import currencies from "../../data/shopCurrencies";
import { getProductCategories } from "../../api/product";
import { url } from "../../services/utils";

function MobileMenu(props) {
    const { mobileMenuState, closeMobileMenu, changeLocale, changeCurrency } = props;
    const [categories, setCategories] = useState([]);
    const [mobileMenu, setMobileMenu] = useState([]);

    const classes = classNames("mobilemenu", {
        "mobilemenu--open": mobileMenuState.sideMenuOpen,
    });

    const setMenuItem = (item, count) => {
        let countLayer = 1; //limit loop up to 4 layer
        countLayer++;
        count = count ? count : 1;

        return {
            type: "link",
            label: item.name,
            url: url.category(item),
            children: item.child?.length > 0 && count <= 4 ? item.child.map((childItem) => setMenuItem(childItem, countLayer)) : [],
        };
    };

    useEffect(() => {
        // getProductCategories({
        //     per_page: "unlimited",
        //     appends: "child_cat",
        //     level: 1,
        // }).then((res) => {
        //     if (res.data && res.data.data) {
        //         let menu = mobileMenu;
        //         res.data.data.map((item) => {
        //             menu.push(setMenuItem(item));
        //         });
        //         menu.push(
        //             // {
        //             //     type: "link",
        //             //     label: "All Brands",
        //             //     url: "/all-brands",
        //             //     children: [],
        //             // },
        //             {
        //                 type: "link",
        //                 label: "Our Service",
        //                 url: "/our-service",
        //                 children: [],
        //             },
        //             {
        //                 type: "link",
        //                 label: "Contact Us",
        //                 url: "/contact-us",
        //                 children: [],
        //             }
        //         );
        //         setCategories(res.data.data);
        //     }
        // });
        let menu = mobileMenu;
        menu.push(
            {
                type: "link",
                label: "User Profile",
                url: "/account/profile",
                children: [],
            },
            {
                type: "link",
                label: "Change Password",
                url: "/account/password",
                children: [],
            },
            {
                type: "link",
                label: "Departure",
                url: "/account/departure",
                children: [],
            },
            {
                type: "link",
                label: "Finance",
                url: "/account/finance",
                children: [],
            },
            {
                type: "link",
                label: "Asset",
                url: "/account/asset",
                children: [],
            },
            {
                type: "link",
                label: "Health",
                url: "/account/health",
                children: [],
            },
            {
                type: "link",
                label: "Pet",
                url: "/account/pet",
                children: [],
            },
            {
                type: "link",
                label: "Message",
                url: "/account/message",
                children: [],
            },
            {
                type: "link",
                label: "My Protector",
                url: "/account/my-protector",
                children: [],
            },
            {
                type: "link",
                label: "I'm a Protector for",
                url: "/account/protector-for",
                children: [],
            },
            {
                type: "link",
                label: "Our Service",
                url: "/our-service",
                children: [],
            },
            {
                type: "link",
                label: "About Us",
                url: "/about-us",
                children: [],
            },
            {
                type: "link",
                label: "Contact Us",
                url: "/contact-us",
                children: [],
            },
            {
                type: "link",
                label: "Log Out",
                url: "/login",
                children: [],
            }
        );
        setMobileMenu(menu);
    }, []);

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === "language") {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.data.type === "currency") {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
        if (item.type === "link") {
            closeMobileMenu();
        }
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks links={mobileMenu} onItemClick={handleItemClick} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);

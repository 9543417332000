// react
import React, { useState, useEffect } from "react";

// third-party
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

// application
import Indicator from "./Indicator";
import { Person20Svg } from "../../svg";
import * as AUTH from "../../api/auth";
import * as USER from "../../api/user";
import { updateUser } from "../../store/user";
import { mobileUserMenuOpen } from "../../store/mobile-menu";
import { checkUnauthorized, getDefaultAvatar, showGeneralError, useWindowSize } from "../../services/utils";
import { useSelector, useDispatch } from "react-redux";
import accountMenuList from "../../data/accountMenuList";
import OpenReplay from "@openreplay/tracker";
import trackerAxios from "@openreplay/tracker-axios";

const tracker = new OpenReplay({
    projectKey: process.env.REACT_APP_PROJECT_KEY,
    ingestPoint: process.env.REACT_APP_OPENREPLAY_URL,
    // onStart: ({ sessionID }) => console.log("OpenReplay tracker started with session: ", sessionID),
});

// make sure only start tracker on prod site & not headless user agent
function isValidToStartRecord() {
    return (
        process.env.NODE_ENV == "production" &&
        process.env.REACT_APP_API_URL == "https://api.upackaging.com.my/" &&
        /^.*(headless).*/gi.test(window?.navigator?.userAgent) == false &&
        window.location.pathname !== "/goto-404" &&
        window.location.pathname !== "/404-not-found"
    );
}

if (isValidToStartRecord()) {
    tracker.start();
    tracker.use(trackerAxios({ failuresOnly: false }));
}

const defaultAvatar = getDefaultAvatar();

export default function IndicatorAccount(props) {
    const token = AUTH.getAccessToken();
    const { from } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        formState: { errors },
        setError,
    } = useForm();
    const [apiLoading, setApiLoading] = useState(false);
    let user = useSelector((state) => state?.user?.profile);
    const windowSize = useWindowSize();
    const avatar = user ? user.image : defaultAvatar;
    const [imgError, setImgError] = useState(false);
    const [closeIndicator, setCloseIndicator] = useState(false);

    // get user profile if token changed
    useEffect(() => {
        let canceled = false;
        if (token && from === "header") {
            USER.getProfile()
                .then((res) => {
                    if (res.data.user_source_type.toString() !== process.env.REACT_APP_PORTAL_TYPE) {
                        // if user acc is not member type, force logout
                        AUTH.logout(history);
                    } else if (res.data) {
                        if (isValidToStartRecord()) tracker.setUserID(res.data.full_name + "(" + res.data.email + ")");
                        dispatch(
                            updateUser({
                                title: res.data.title,
                                uuid: res.data.uuid,
                                firstName: res.data.first_name,
                                lastName: res.data.last_name,
                                fullName: res.data.full_name,
                                username: res.data.username,
                                image: res.data.profile_image,
                                email: res.data.email,
                                mobilePrefix: res.data.mobile_prefix,
                                mobile: res.data.mobile_number,
                                totalPoint: res.data.total_point,
                            })
                        );
                    }
                })
                .catch((err) => {
                    if (!checkUnauthorized(err, history)) {
                        if (err.http_code == 500) {
                            showGeneralError();
                            AUTH.removeUserFromStorage();
                            history.push("/login");
                        } else if (err.http_code == 422) {
                            toast.error("We have encountered an error processing your request. Please try again later.", {
                                autoClose: 3000,
                            });
                        }
                    }
                });
        }
    }, [token]);

    const onSubmit = (data) => {
        setApiLoading(true);
        let formData = {
            ...data,
            portal_type: 2,
        };
        AUTH.login(formData)
            .then((res) => {
                toast.success("Login Successful", { autoClose: 3000 });
                handleCloseAfterRedirect();
                history.push("/");
            })
            .catch((err) => {
                setApiLoading(false);
                if (err.http_code == 422 && err.data?.errors) {
                    addServerErrors(err.data.errors);
                } else if (err.data?.message) {
                    toast.error(err.data?.message);
                } else if (err.http_code == 403 && err.data?.errors) {
                    Object.keys(err.data?.errors).map((errorKey) => {
                        if (errorKey == "account.unverified") {
                            setError("email", {
                                type: "server",
                                message: "Email haven't verified. Please verify and try login again.",
                            });
                        } else toast.error(err.data?.errors[errorKey]?.message);
                    });
                } else {
                    showGeneralError();
                }
            });
    };

    const addServerErrors = (errors) => {
        let validationErrors = {};
        for (const errorKey in errors) {
            let key = errorKey.split(".")[0];
            if (!Object.keys(validationErrors).includes(key)) {
                validationErrors[key] = "";
            }
            validationErrors[key] += ", " + errors[errorKey].message;
            return Object.keys(validationErrors).forEach((key) => {
                setError(key, {
                    type: "server",
                    message: errors[errorKey].message,
                });
            });
        }
    };

    function handleLogout() {
        toast.info("Logout Successful", { autoClose: 2000 });
        handleCloseAfterRedirect();
        AUTH.logout(history);
    }

    const handleCloseAfterRedirect = () => {
        setCloseIndicator(true);
        setTimeout(() => {
            setCloseIndicator(false);
        }, 500);
    };

    const noAuthDropDown = (
        <div className="account-menu">
            <form className="account-menu__form" onSubmit={handleSubmit(onSubmit)}>
                <div className="account-menu__form-title">Log In to Your Account</div>
                <div className="form-group">
                    <label htmlFor="header-signin-email" className="sr-only">
                        Email address
                    </label>
                    <input
                        {...register("email", {
                            required: {
                                value: true,
                                message: "Email is required",
                            },
                            maxLength: {
                                value: 255,
                                message: "Email should not exceed 255 characters",
                            },
                            minLength: {
                                value: 2,
                                message: "Email should more than 2 characters",
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Email entered is invalid",
                            },
                        })}
                        id="header-signin-email"
                        name="email"
                        type="email"
                        className={`form-control form-control-sm ${errors.email == null ? "" : "is-invalid"}`}
                        placeholder="Email address"
                    />
                    {errors.email == null ? "" : <div className="invalid-feedback">{errors.email.message}</div>}
                </div>

                <div className="form-group">
                    <label htmlFor="header-signin-password" className="sr-only">
                        Password
                    </label>
                    <div className="account-menu__form-forgot">
                        <input
                            {...register("password", {
                                required: {
                                    value: true,
                                    message: "Password is required",
                                },
                                minLength: {
                                    value: 8,
                                    message: "Password should more than 8 characters",
                                },
                            })}
                            id="header-signin-password"
                            name="password"
                            type="password"
                            autocomplete="on"
                            className={`form-control form-control-sm ${errors.password == null ? "" : "is-invalid"}`}
                            placeholder="Password"
                        />
                        <Link to="/forgot-password" className="account-menu__form-forgot-link" onClick={() => handleCloseAfterRedirect()}>
                            Forgot?
                        </Link>
                    </div>
                    {errors.password == null ? "" : <div className="invalid-feedback">{errors.password.message}</div>}
                </div>

                <div className="form-group account-menu__form-button">
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className={apiLoading ? "btn btn-primary btn-sm btn-loading w-100" : "btn btn-primary btn-sm w-100"}>
                        Login
                    </button>
                </div>
                <div className="account-menu__form-link">
                    <Link to="/register" onClick={() => handleCloseAfterRedirect()}>
                        Create New Account
                    </Link>
                </div>
            </form>
        </div>
    );

    const accountDropdown = (
        <div className="account-menu">
            <div className="account-menu__divider" />
            <Link to="/account/profile" className="account-menu__user">
                <div className="account-menu__user-avatar">
                    {imgError ? (
                        <img src={getDefaultAvatar()} alt="user-avatar" />
                    ) : (
                        <img src={avatar ? avatar : getDefaultAvatar()} alt="user-avatar" onError={(e) => setImgError(true)} />
                    )}
                </div>
                <div className="account-menu__user-info">
                    <div className="account-menu__user-name">{user?.fullName}</div>
                    <div className="account-menu__user-email">{user?.email}</div>
                </div>
            </Link>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                {accountMenuList.map((item) => {
                    return (
                        <li key={item.name}>
                            <Link to={item.url}>{item.name}</Link>
                        </li>
                    );
                })}
            </ul>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <Link to="" onClick={handleLogout}>
                    Log Out
                </Link>
            </ul>
        </div>
    );

    return from == "mobile" && !token ? (
        <Indicator url="/login" icon={<Person20Svg />} />
    ) : windowSize.width < 767 ? (
        <div onClick={() => dispatch(mobileUserMenuOpen())}>
            <Indicator icon={<Person20Svg />} />
        </div>
    ) : (
        <Indicator
            url="/account"
            closeIndicator={closeIndicator}
            dropdown={token ? accountDropdown : noAuthDropDown}
            icon={<Person20Svg />}
        />
    );
}

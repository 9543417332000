import { eventTypeList } from '../../data/generalData';
import { getNoImg, groupBy } from '../../services/utils';
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES, SYNC_CART } from './cartActionTypes';

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, options) {
    return items.findIndex((item) => {
        if (item.product.id !== product.id || item.options.length !== options.length) {
            return false;
        }

        for (let i = 0; i < options.length; i += 1) {
            const option = options[i];
            const itemOption = item.options.find((itemOption) => (
                itemOption.optionId === option.optionId && itemOption.valueId === option.valueId
            ));

            if (!itemOption) {
                return false;
            }
        }

        return true;
    });
}

function calcSubtotal(items) {
    return items.reduce((subtotal, item) => subtotal + item.total, 0);
}

function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity + (item.subProds ? item.subProds.reduce((quantity, subItem) => quantity + subItem.quantity, 0) : 0), 0);
}

function calcTotal(subtotal, extraLines) {
    return subtotal + extraLines.reduce((total, extraLine) => total + extraLine.price, 0);
}

function addItem(state, product, options, quantity) {
    const itemIndex = findItemIndex(state.items, product, options);

    let newItems;
    let { lastItemId } = state;

    if (itemIndex === -1) {
        lastItemId += 1;
        newItems = [...state.items, {
            id: lastItemId,
            product: JSON.parse(JSON.stringify(product)),
            options: JSON.parse(JSON.stringify(options)),
            price: product.price,
            total: product.price * quantity,
            quantity,
        }];
    } else {
        const item = state.items[itemIndex];

        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: item.quantity + quantity,
                total: (item.quantity + quantity) * item.price,
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state,
        lastItemId,
        subtotal,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
    };
}

function removeItem(state, itemId) {
    const { items } = state;
    const newItems = items.filter((item) => item.id !== itemId);

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
    };
}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);

        if (!quantity) {
            return item;
        }

        needUpdate = true;

        return {
            ...item,
            quantity: quantity.value,
            total: quantity.value * item.price,
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const total = calcTotal(subtotal, state.extraLines);

        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            subtotal,
            total,
        };
    }

    return state;
}

function sortItemsByStatus(a, b) {
    // a should come before b in the sorted order
    if (a.status < b.status) {
        return -1;
        // a should come after b in the sorted order
    } else if (a.item > b.item) {
        return 1;
        // and and b are the same
    } else {
        return 0;
    }
}

// sync cart item from api, set some custom field for easy manage
function syncCartList(state, list) {
    let cart = list ? list : [];

    for (let i = 0; i < cart.length; i++) {

        let selectedVariant = cart[i].selected_variant_group
        cart[i].selectedAddons = ''

        if (selectedVariant) {
            cart[i].variantGroupID = selectedVariant.uuid

            // set price
            if (selectedVariant.is_sales) {
                cart[i].price = selectedVariant.offer_price
            } else {
                cart[i].price = selectedVariant.retail_price
            }
            cart[i].onSales = selectedVariant.is_sales
            cart[i].offerPrice = selectedVariant.offer_price
            cart[i].normalPrice = selectedVariant.retail_price


            //set selected varaint
            cart[i].variantGroup = selectedVariant.combinations
            cart[i].stockQty = selectedVariant.stock_count

            // set final price
            cart[i].finalPrice = parseFloat(cart[i].price) * cart[i].quantity
        }

        if (cart[i].selected_addons && cart[i].selected_addons.length > 0) {
            cart[i].selected_addons.map(item => {
                cart[i].selectedAddons += item.uuid + item.index
            })
        }

        cart[i].uuid = cart[i].hash

        // if the product status is inactive
        if (cart[i].product?.status?.code == 90 || cart[i].stockQty < cart[i].quantity)
            cart[i].status = 90

        // set banner image
        cart[i].image = cart[i].product.images && cart[i].product.images[0]
            ? cart[i].product.images[0].url
            : getNoImg()
    }

    let normalProd = cart.filter(item => item.sub_event_info == null || item.status == 90)
    let subProds = cart.filter(item => item.sub_event_info !== null && item.status !== 90)
    // let comboProd = normalProd.filter(item => item.event_pwp_info?.type?.code == eventTypeList.find(event => event.name == 'PWP Combo').code)
    // console.log(comboProd)


    //group event sub prod base on main prod
    const grouped = groupBy(subProds, (item) => item.parent_hash);

    //insert sub prods arr into main prod 
    normalProd.map(item => {
        if (grouped.find(addOnDeal => addOnDeal.name == item.uuid)) {
            item.subProds = grouped.find(addOnDeal => addOnDeal.name == item.uuid)?.value
            item.onSales = true
            item.price = item.selected_variant_group.offer_price
            item.finalPrice = parseFloat(item.price) * item.quantity
        }
    })
    cart = normalProd

    return {
        items: cart.sort(sortItemsByStatus),
        // total: parseFloat(calcTotal(cart)).toFixed(2),
        quantity: calcQuantity(cart),
        // productSubtotal: parseFloat(calcTotal(cart.filter((item) => item.checkout == 1))).toFixed(2),
        // checkoutQuantity: calcQuantity(cart.filter((item) => item.checkout == 1)),
        // checkoutTotal: state.checkoutTotal ? state.checkoutTotal : parseFloat(calcCheckoutTotal(cart.filter((item) => item.checkout == 1))).toFixed(2),
    };
}

/*
* item example:
* {
*   id: 1,
*   product: {...}
*   options: [
*     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
*   ],
*   price: 250,
*   quantity: 2,
*   total: 500
* }
* extraLine example:
* {
*   type: 'shipping',
*   title: 'Shipping',
*   price: 25
* }
*/
const initialState = {
    lastItemId: 0,
    quantity: 0,
    items: [],
    total: 0,
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CART_ADD_ITEM:
            return addItem(state, action.product, action.options, action.quantity);

        case CART_REMOVE_ITEM:
            return removeItem(state, action.itemId);

        case CART_UPDATE_QUANTITIES:
            return updateQuantities(state, action.quantities);

        case SYNC_CART:
            return syncCartList(state, action.list);

        default:
            return state;
    }
}

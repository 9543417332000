export default {
    name: 'Elevate',
    fullName: 'Elevate',
    url: 'https://uat.syopex.com',
    color: '#FF9400',
    meta_title: 'Elevate',
    meta_description: '',
    meta_keyword: '',
    author: {
        name: 'Ant Internet Shd Bhd',
        profile_url: 'https://ant-internet.com/',
    },
    contacts: {
        address: '715 Fake Street, New York 10021 USA',
        email: 'stroyka@example.com',
        phone: '(800) 060-0730',
    },
};

// react
import React, { useEffect, useState, Suspense, lazy } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import BlockLoader from "./blocks/BlockLoader";

// data stubs
import * as GENERAL from "../api/general";
import { checkVisitorID, redirectTo404 } from "../services/utils";
import { getAccessToken } from "../api/auth";
import { getMyCart } from "../api/cart";
import store from "../store";
import { updateUser } from "../store/user";
import MessengerCustomerChat from "react-messenger-customer-chat";

// application
import Quickview from "./shared/Quickview";
import Header from "./header";
import Footer from "./footer";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";
import MobileUserMenu from "./mobile/MobileUserMenu";

// pages
const HomePage = lazy(() => import("./home/HomePage"));
const AccountLayout = lazy(() => import("./account/AccountLayout"));
const AccountPageLogin = lazy(() => import("./account/AccountPageLogin"));
const AccountPageRegister = lazy(() => import("./account/AccountPageRegister"));

const OrderDetail = lazy(() => import("./shop/OrderDetail"));
const PageCart = lazy(() => import("./shop/ShopPageCart"));
const PageCheckout = lazy(() => import("./shop/ShopPageCheckout"));
const ShopPageProduct = lazy(() => import("./shop/ShopPageProduct"));
const ShopPageBrands = lazy(() => import("./shop/ShopPageBrands"));
const ShopPageCategory = lazy(() => import("./shop/ShopPageCategory"));
const ShopPageOrderSuccess = lazy(() => import("./shop/ShopPageOrderSuccess"));
const ShopPageEvent = lazy(() => import("./shop/ShopPageEvent"));
const ShopPageAddOnDeals = lazy(() => import("./shop/ShopPageAddOnDeals"));
const ShopPageComboDeals = lazy(() => import("./shop/ShopPageComboDeals"));

const SitePageAboutUs = lazy(() => import("./site/SitePageAboutUs"));
const SitePageContactUs = lazy(() => import("./site/SitePageContactUs"));
const SitePageNotFound = lazy(() => import("./site/SitePageNotFound"));
const SitePageForgotPassword = lazy(() => import("./site/SitePageForgotPassword"));
const SitePageResendVerification = lazy(() => import("./site/SitePageResendVerification"));
const SitePageResetPassword = lazy(() => import("./site/SitePageResetPassword"));
const SitePagePrivacyPolicy = lazy(() => import("./site/SitePagePrivacyPolicy"));
const SitePageFAQ = lazy(() => import("./site/SitePageFAQ"));
const SitePageSolutions = lazy(() => import("./site/SitePageSolutions"));
const SitePageBlog = lazy(() => import("./site/SitePageBlog"));
const SitePageDynamic = lazy(() => import("./site/SitePageDynamic"));

function Layout(props) {
    const { match, settings } = props;
    const [socialLinks, setSocialLinks] = useState(null);
    const [storeInfo, setStoreInfo] = useState(null);
    const [finishLoadSetting, setFinishLoadSetting] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const token = getAccessToken();

    useEffect(() => {
        if (checkVisitorID()) {
            // getMyCart(store);
        }
        if (!token) {
            store.dispatch(updateUser(null));
        }
    }, [token]);

    useEffect(() => {
        if (settings) {
            setSocialLinks(settings.system?.social);
            setStoreInfo(settings.store);
            setFinishLoadSetting(true);
        }
    }, [settings]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        if (firstLoad) setFirstLoad(false);
        // else getMyCart(store);
    }, []);

    const handleScroll = () => {
        if (document.getElementById("mobile-sticky")) {
            if (window.pageYOffset > 100) {
                document.getElementById("mobile-sticky").classList.add("fixed");
            } else {
                document.getElementById("mobile-sticky").classList.remove("fixed");
            }
        }
    };

    // page use gray bg instead of white
    const checkPageReturnClass = (pathname) => {
        if (
            pathname.indexOf("/voucher-details/") > -1 ||
            pathname == "/cart" ||
            pathname == "/checkout" ||
            pathname == "/login" ||
            pathname == "/register" ||
            pathname == "/resend-verification" ||
            pathname == "/forgot-password" ||
            pathname.indexOf("/order-tracking/") > -1
        )
            return "site__body grey";
        else return "site__body";
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{GENERAL.getSEOSettings("meta_title")}</title>
                <meta name="description" content={GENERAL.getSEOSettings("meta_description")} />
                <meta name="keywords" content={GENERAL.getSEOSettings("meta_keyword")} />

                <meta property="og:title" content={GENERAL.getSEOSettings("meta_title")} />
                <meta property="og:description" content={GENERAL.getSEOSettings("meta_description")} />

                <meta property="twitter:title" content={GENERAL.getSEOSettings("meta_title")} />
                <meta property="twitter:description" content={GENERAL.getSEOSettings("meta_description")} />
            </Helmet>
            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu />

            <MobileUserMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header id="sticky" className="site__header sticky-header d-lg-block d-none">
                    <Header />
                </header>
                <Suspense
                    fallback={
                        <div className="block-empty-page">
                            <BlockLoader />
                        </div>
                    }
                >
                    <div id="site-body" className={checkPageReturnClass(window.location.pathname)}>
                        <Switch>
                            {/*
                            // Home
                            */}
                            <Route
                                exact
                                path={`${match.path}`}
                                render={(props) => (
                                    <HomePage
                                        {...props}
                                        banners={storeInfo?.home_section_cms?.first}
                                        trendingCats={storeInfo?.homeSectionCMS?.second}
                                        cmsSection={storeInfo?.home_section_cms?.forth}
                                        finishLoad={finishLoadSetting}
                                    />
                                )}
                            />
                            {token
                                ? null
                                : [
                                      <Route key="register" exact path="/register" component={AccountPageRegister} />,
                                      <Route key="login" exact path="/login" component={AccountPageLogin} />,
                                  ]}
                            {/*
                            // Shop
                            */}
                            <Route
                                exact
                                path="/brand/:brandSlug"
                                render={(props) => (
                                    <ShopPageCategory
                                        {...props}
                                        columns={3}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        brandSlug={props.match.params.brandSlug}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/category/:categorySlug"
                                render={(props) => (
                                    <ShopPageCategory
                                        {...props}
                                        columns={3}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        categorySlug={props.match.params.categorySlug}
                                    />
                                )}
                            />
                            <Route
                                path="/search-products"
                                render={(props) => (
                                    <ShopPageCategory
                                        {...props}
                                        columns={4}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        searchValue={decodeURI(
                                            props.location?.search ? props.location.search.substring(1)?.split("=")[1] : ""
                                        )}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path="/product/:productSlug"
                                render={(props) => (
                                    <ShopPageProduct {...props} layout="standard" productSlug={props.match.params.productSlug} />
                                )}
                            />

                            <Route exact path="/cart" component={PageCart} />
                            <Route exact path="/checkout" component={PageCheckout} />
                            <Route exact path="/order-success" component={ShopPageOrderSuccess} />
                            <Route exact path="/all-brands" component={ShopPageBrands} />
                            <Route
                                exact
                                path="/addon-deal/:productSlug"
                                render={(props) => <ShopPageAddOnDeals {...props} productSlug={props.match.params.productSlug} />}
                            />
                            <Route
                                exact
                                path="/combo-deal/:productSlug"
                                render={(props) => <ShopPageComboDeals {...props} productSlug={props.match.params.productSlug} />}
                            />
                            {/*
                            // Account
                            */}
                            <Route path="/account" component={AccountLayout} />
                            <Route
                                exact
                                path="/order-tracking/:orderCode"
                                render={(props) => <OrderDetail {...props} fromPublic={true} />}
                            />

                            {/*
                            // Site
                            */}
                            <Route exact path="/forgot-password" component={SitePageForgotPassword} />
                            <Route exact path="/password-reset" component={SitePageResetPassword} />
                            <Route exact path="/resend-verification" component={SitePageResendVerification} />
                            <Route exact path="/faq" component={SitePageFAQ} />
                            {/* <Route exact path="/shipping-information" component={SitePageShippingInformation} /> */}
                            <Route exact path="/about-us" component={SitePageAboutUs} />
                            <Route exact path="/policy" component={SitePagePrivacyPolicy} />
                            <Route exact path="/contact-us" component={SitePageContactUs} />
                            <Route exact path="/our-service" component={SitePageSolutions} />
                            <Route
                                exact
                                path="/event/:slug"
                                render={(props) => <ShopPageEvent {...props} eventSlug={props.match.params.slug} />}
                            />
                            <Route
                                exact
                                path="/blog/:slug"
                                render={(props) => <SitePageBlog {...props} blogSlug={props.match.params.slug} />}
                            />
                            <Route
                                exact
                                path="/page/:slug"
                                render={(props) => <SitePageDynamic {...props} pageSlug={props.match.params.slug} />}
                            />
                            {/*
                            // Page Not Found
                            */}

                            <Route exact path="/goto-404" component={SitePageNotFound} />
                            <Route exact path="/404-not-found" component={SitePageNotFound} />

                            <Route component={redirectTo404} />
                        </Switch>
                    </div>
                </Suspense>

                <footer className="site__footer">
                    <Footer socialLinks={socialLinks} storeInfo={storeInfo} />
                </footer>
            </div>
        </React.Fragment>
    );
}

export default Layout;

import React, { useEffect, useState } from "react";
import { redirectTo404 } from "../../services/utils";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Currency from "../shared/Currency";

function SitePageInvoice(props) {
    const order = localStorage.getItem("printOrder") ? JSON.parse(localStorage.getItem("printOrder")) : null;
    let settings = useSelector((state) => state.generalInfo?.info);
    let companyInfo = settings?.company_info;
    let contactInfo = settings?.contact_info;

    useEffect(() => {
        if (!order) {
            redirectTo404();
        }
    }, [order]);

    useEffect(() => {
        setTimeout(function () {
            window.print();
        }, 2000);

        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return localStorage.removeItem("printOrder");
        });
    }, []);

    return (
        <React.Fragment>
            {order && settings ? (
                <div className="block mt-4">
                    <div className={`container`}>
                        <div className="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                            <div>
                                <div className="logo-wrapper mb-2">
                                    <img src="images/logos/logo.png" alt="logo" className="header-logo" />
                                </div>
                                <h5>{companyInfo.name}</h5>
                                <p>
                                    {companyInfo.address1}
                                    <br />
                                    {companyInfo.postcode}, {companyInfo.city}
                                    <br />
                                    {companyInfo.state}, {companyInfo.country}
                                    <br />
                                    {contactInfo.number1 ? contactInfo.number1 : ""}, {contactInfo.number2 ? contactInfo.number2 : ""}
                                    <br />
                                    {contactInfo.email ? contactInfo.email : ""}
                                    <br />
                                </p>
                            </div>
                            <div className="mt-md-0 mt-2">
                                <h4 className="invoice-title" style={{ marginRight: "0rem" }}>
                                    Invoice <span className="invoice-number">#{order.order_code}</span>
                                </h4>
                                <div className="invoice-date-wrapper mt-2">
                                    <p className="invoice-date-title">
                                        Date Issued:{" "}
                                        <span className="invoice-date font-weight-bolder">
                                            {moment(order.created_at).format("DD MMM YYYY")}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr className="my-2 mb-4" />
                        <div className="row pb-2">
                            <div className="col-sm-6">
                                <h6 className="mb-2">Invoice To:</h6>
                                <h6 className="mb-25">{order.billing_info?.first_name + " " + order.billing_info?.last_name}</h6>
                                <p className="mb-25">
                                    {order.billing_info?.address} <br />
                                    {order.billing_info?.postcode + ", " + order.billing_info?.city}
                                    <br />
                                    {(order.billing_info?.state?.name ? order.billing_info?.state?.name : "") +
                                        ", " +
                                        (order.billing_info?.country?.name ? order.billing_info?.country?.name : "")}
                                    <br />
                                    {order.billing_info?.mobile_number_prefix + order.billing_info?.mobile_number}
                                    <br />
                                    {order.billing_info?.email}
                                </p>
                            </div>
                            <div className="col-sm-6 mt-sm-0 mt-2">
                                <h6 className="mb-2">Payment Details:</h6>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-1">Total Due:</td>
                                            <td>
                                                <strong>RM {parseFloat(order.total_amount).toFixed(2)}</strong>
                                            </td>
                                        </tr>
                                        {order.payment_info && order.payment_info?.bank ? (
                                            <tr>
                                                <td className="pr-1">Bank :</td>
                                                <td className="font-weight-bolder">{order.payment_info?.bank}</td>
                                            </tr>
                                        ) : null}
                                        {order.payment_info && order.payment_info?.payment_method ? (
                                            <tr>
                                                <td className="pr-1">Payment Method :</td>
                                                <td className="font-weight-bolder">{(order.payment_info?.payment_method).toUpperCase()}</td>
                                            </tr>
                                        ) : null}
                                        {order.payment_info && order.payment_info?.reference_number ? (
                                            <tr>
                                                <td className="pr-1">Reference No :</td>
                                                <td className="font-weight-bolder">{order.payment_info?.reference_number}</td>
                                            </tr>
                                        ) : null}
                                        {order.payment_info && order.payment_info?.transaction_number ? (
                                            <tr>
                                                <td className="pr-1">Transaction No :</td>
                                                <td className="font-weight-bolder">{order.payment_info?.transaction_number}</td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-table w-100 invoice-table">
                                <table className="order-details">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody className="card-table__body card-table__body--merge-rows">
                                        {order.order_details.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>
                                                        {item.product_detail?.product?.name + " x " + item.quantity}
                                                        <ul className="cart-table__options mt-0">
                                                            <li>
                                                                {item.product_detail?.variant_group?.combinations.length == 1 &&
                                                                item.product_detail?.variant_group?.combinations[0]?.name == "main"
                                                                    ? null // if no variant options
                                                                    : item.product_detail?.variant_group?.combinations.map((opt, index) => {
                                                                          return (
                                                                              opt.name +
                                                                              (item.product_detail?.variant_group?.combinations.length !==
                                                                              index + 1
                                                                                  ? ", "
                                                                                  : "")
                                                                          );
                                                                      })}
                                                            </li>
                                                            {item.addon_info?.length > 0
                                                                ? item.addon_info?.map((addon, index) => {
                                                                      return (
                                                                          <li>
                                                                              {"+ " +
                                                                                  addon.addon_item.additional_json.options[addon.index]
                                                                                      ?.name}
                                                                          </li>
                                                                      );
                                                                  })
                                                                : null}
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <Currency value={item.total_price} />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                    <tbody className="card-table__body card-table__body--merge-rows total-summary">
                                        <tr>
                                            <th>Subtotal</th>
                                            <td>
                                                <Currency value={order.subtotal_amount} />
                                            </td>
                                        </tr>

                                        {order.total_tax_amount && parseFloat(order.total_tax_amount) > 0 ? (
                                            <tr>
                                                <th>Tax Amount</th>
                                                <td>
                                                    <Currency value={order.total_tax_amount} />
                                                </td>
                                            </tr>
                                        ) : null}

                                        {order?.total_shipping_fee && order?.shipping_info?.type == 2 ? (
                                            <tr>
                                                <th>Shipping</th>
                                                <td>
                                                    <Currency value={order.total_shipping_fee} />
                                                </td>
                                            </tr>
                                        ) : null}

                                        {order.event_data_info?.vouchers && order.event_data_info?.vouchers[0] ? (
                                            <tr>
                                                <th>Vocuher Discount</th>
                                                <td>
                                                    {"- "}
                                                    <Currency value={order.voucher_amount} />
                                                </td>
                                            </tr>
                                        ) : null}

                                        {order.adjust_amount ? (
                                            <tr>
                                                <th>Adjust Amount</th>
                                                <td>
                                                    {order.adjust_amount.toString().charAt(0) == "-" ? "- " : ""}
                                                    {order.adjust_amount.toString().charAt(0) == "-" ? (
                                                        <Currency
                                                            value={parseFloat(order.adjust_amount.toString().substring(1)).toFixed(2)}
                                                        />
                                                    ) : (
                                                        <Currency value={order.adjust_amount} />
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                    <tfoot className="total-summary">
                                        <tr>
                                            <th>Total</th>
                                            <td className="total-price">
                                                <Currency value={order.total_amount} />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}

export default SitePageInvoice;

import { getAuthHeader, paramBuilder } from "../services/utils";
const axios = require("axios");


export async function getEvents(params) {
  const queryString = paramBuilder(params)

  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/event" + queryString
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        }
      });
  });
}


export async function getEventVouchers() {
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/event-voucher";
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function getEventByCode(code) {
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/event-code/" + code;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function getEventByProduct(slug) {
  let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/get-product-active-event/" + slug;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: getAuthHeader(),
        withCredentials: true,
        timeout: 180000,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject({ http_code: error.response.status, data: error.response.data });
          } else {
            reject(error.response.data);
          }
        }
      });
  });
}
import { STORE_GENERAL_INFO, STORE_COUNTRIES } from './generalInfoTypes';

const initialState = {
    info: null,
    countries: []
};

export default function generalInfoReducer(state = initialState, action) {
    if (action.type === STORE_GENERAL_INFO) {
        return { ...state, info: action.generalInfo };
    } else if (action.type === STORE_COUNTRIES) {
        return { ...state, countries: action.countries };
    }
    return state;
}
